import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

const StyledH2 = styled.h2`
  text-shadow: rgba(0, 0, 0, 0.6) 0px 3px 12px;
`

const PageTestimonialsHeroSection = () => (
  <div style={{ display: "grid" }}>
    <StaticImage
      style={{
        gridArea: "1/1",
        height: 400,
      }}
      width={1920}
      layout="constrained"
      alt=""
      src="../../images/header/testimonials-bg-2.jpg"
      placeholder='tracedSVG'
      formats={["auto", "webp", "avif"]}
    />
    <div
      style={{
        gridArea: "1/1",
        position: "relative",
        placeItems: "center",
        display: "grid",
      }}
    >
      <div className='w-full max-w-screen-lg flex flex-col xl:flex-row items-center mx-auto'>
        <div className='w-11/12 flex flex-col items-center mx-auto'>
          <h1 className="text-xl text-white font-medium text-center uppercase mb-4">Testimonials</h1>
          <StyledH2 className='text-3xl md:text-5xl font-extrabold text-white uppercase text-center'>
            <span className='leading-tight'>Don’t take our word for it.</span>
          </StyledH2>
          <p className="text-xl text-white font-medium text-center mt-4">Here’s what our clients are saying about the IT Proactive Team.</p>
        </div>
      </div>
    </div>
  </div>
)

export default PageTestimonialsHeroSection